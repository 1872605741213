import React, { useContext } from 'react'
import { GlobalDataContext } from '../../context/context'

import { ButtonContent } from '../global/boton/ButtonContent'

import { HiChevronRight } from 'react-icons/hi'
import { BiChevronRightSquare } from 'react-icons/bi'

const BlockContent = ({ title, texts, images, bgImages, listServices, listAbout }) => {

  const { rpdata } = useContext(GlobalDataContext)

  return (
    <section
      className='w-full h-full bg-cover bg-no-repeat bg-center relative before:absolute bgGradienteWhite svgTriangulobotton'
      style={{
        backgroundImage: `url("${bgImages}")`,
        backgroundAttachment: 'fixed'
      }}
    >
      <div className='w-4/5 mx-auto flex flex-col md:flex-row relative pb-32'>
        <div className='w-full md:-1/2 mb-10 md:mb-0 relative'
          data-aos="flip-up"
          data-aos-duration="2000"
        >
          <picture>
            <img
              src={images}
              alt='images'
              className='w-full h-full rounded-md shadow-md'
            />
          </picture>
          <img
          src='https://firebasestorage.googleapis.com/v0/b/imagenes-de-stock-y-redes.appspot.com/o/7.png?alt=media&token=33745daf-7895-4174-b6bb-6bba43be661c&_gl=1*1bh189z*_ga*ODgwNTY3MjU1LjE2NjEwMTIyMDI.*_ga_CW55HF8NVT*MTY4NTk5NTAwOC41Mi4xLjE2ODU5OTUwMzcuMC4wLjA.'
          alt='notimg'
          className='absolute w-[180px] h-[180px] top-56 left-0 md:-left-24 z-50'
          />
        </div>

        <div className='w-full md:-1/2 flex flex-col items-center text-center md:text-start md:items-start md:justify-center gap-10 px-5 md:px-20'>
          <h1 className='text-[30px] md:text-[50px]'>{title}</h1>
          <p>{texts}</p>

          {
            listServices ?
              <ul className="grid grid-cols-1 md:grid-cols-2 gap-5 pb-5">
                {rpdata?.dbServices?.slice(0, 8).map((item, index) => {
                  return (
                    <li key={index} className="flex items-center gap-3">
                      <BiChevronRightSquare color="#0077FF" />
                      <span>{item.name}</span>
                    </li>
                  )
                })}
              </ul>
              : null
          }

          {
            listAbout ?
              <ul className='grid grid-cols-1 md:grid-cols-2 gap-5 pb-5'>
                {
                  rpdata?.dbAbout?.[0].list.length > 1 ?
                    rpdata?.dbAbout?.[0].list.map((item, index) => {
                      return (

                        <li key={index} className="flex items-center gap-1">
                          <HiChevronRight />
                          <span>{item}</span>
                        </li>
                      )
                    })
                    : null
                }
              </ul>
              : null
          }

          <div>
            <ButtonContent btnStyle={'three'} />
          </div>
        </div>
      </div>

    </section>
  )
}

export default BlockContent